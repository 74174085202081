import PropTypes from "prop-types";
import colors from "constants/colors";

function IconCheck({ fill }) {
    return (
        <svg viewBox="0 0 45 35">
            <g fill={fill}>
                <path d="M12.837 35L0 22.053l3.072-3.072 9.765 9.765L41.693 0l3.072 3.072z" />
            </g>
        </svg>
    );
}

IconCheck.propTypes = {
    fill: PropTypes.string
};

IconCheck.defaultProps = {
    fill: colors.darkNight
};

export default IconCheck;
