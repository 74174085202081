import styled, { css } from "styled-components";

import media from "constants/media";
import colors from "constants/colors";

export const CookieConsentStyled = styled.div`
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 12;

    width: calc(100% - 40px);
    max-width: 290px;
    padding: 0 25px 25px;

    background: var(--color-white);
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);

    ${media.mdUp`
        padding: 0 30px 30px;
    `}
`;

export const Close = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;

    width: 12px;

    cursor: pointer;

    svg {
        width: 100%;
    }
`;

export const Heading = styled.h2`
    margin: 25px 20px 6px 0;

    font-weight: 500;
    font-size: 16px;
    line-height: 25px;

    ${media.mdUp`
        font-size: 18px;
    `}
`;

export const Text = styled.p`
    margin: 0 20px 14px 0;

    font-size: 14px;
    line-height: 20px;

    ${media.mdUp`
        font-size: 15px;
        line-height: 25px;
    `}
`;

export const StyledLink = styled.a`
    color: var(--color-peach);
    text-decoration: underline;
`;

export const Option = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;

    :last-of-type {
        margin-bottom: 26px;
    }
`;

export const OptionButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    background: transparent;
    border: 1px solid var(--color-lightGrey);

    border-radius: 50%;
    cursor: pointer;

    transition: 0.2s all ease-in-out;

    ${({ isActive }) =>
        isActive &&
        css`
            background: ${({ isNegative }) =>
                isNegative ? colors.red : colors.green};
            border: 1px solid transparent;
        `}

    &:not(:last-of-type) {
        margin-right: 10px;
    }

    svg {
        width: 12px;

        g {
            transition: 0.2s fill ease-in-out;

            fill: ${({ isActive }) =>
                isActive ? colors.white : colors.lightGrey};
        }
    }
`;

export const OptionText = styled.span`
    margin-left: 20px;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    ${media.mdUp`
        font-size: 15px;
        line-height: 25px;
    `}
`;

export const Confirm = styled.button`
    display: block;
    width: 100%;
    max-width: 244px;
    height: 45px;
    margin: 0 auto;

    font-weight: 600;
    font-size: 13px;
    text-align: center;

    background: ${colors.darkGreen};
    border-radius: 22px;
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
