import { useState, useEffect } from "react";
import Cookies from "js-cookie";

import Link from "next/link";
import Button from "components/shared/button/Button";
import IconCheck from "components/shared/svg/IconCheck";
import IconClose from "components/shared/svg/IconClose";
import {
    CookieConsentStyled,
    Close,
    Heading,
    Text,
    StyledLink,
    Option,
    OptionButton,
    OptionText,
    Controls
} from "./CookieConsent.styled";

function pushPreferencesToDataLayer(statistics, marketing) {
    window?.dataLayer?.push({ event: "cookies_preferences" });

    if (statistics) {
        window?.dataLayer?.push({ event: "cookies_statistics" });
    }

    if (marketing) {
        window?.dataLayer?.push({ event: "cookies_marketing" });
    }
}

export default function CookieConsent() {
    const [preferencesCookies, setPreferencesCookies] = useState(true);
    const [statisticsCookies, setStatisticsCookies] = useState(true);
    const [marketingCookies, setMarketingCookies] = useState(true);
    const [savedPreferences, setSavedPreferences] = useState(true);

    useEffect(() => {
        // Checks if the cookies have been already set locally.
        const preferencesCookieExists = Cookies.get("cookiesPreferences");
        const statisticsCookieExists = Cookies.get("cookiesStatistics");
        const marketingCookieExists = Cookies.get("cookiesMarketing");

        if (
            preferencesCookieExists === undefined ||
            statisticsCookieExists === undefined ||
            marketingCookieExists === undefined
        ) {
            setSavedPreferences(false);
        }
    }, []);

    function saveCookiePreferences() {
        // Saves the selected preferences into the cookies, pushes them to the dataLayer and hides the popup.

        // Get baseUrl from environment
        const baseUrl = new URL("/", process.env.NEXT_PUBLIC_BASE_URL);

        // Remove protocol from url.
        const domain = baseUrl.hostname;

        Cookies.set("cookiesPreferences", preferencesCookies, {
            expires: 7,
            domain
        });
        Cookies.set("cookiesStatistics", statisticsCookies, {
            expires: 7,
            domain
        });
        Cookies.set("cookiesMarketing", marketingCookies, {
            expires: 7,
            domain
        });

        pushPreferencesToDataLayer(statisticsCookies, marketingCookies);
        setSavedPreferences(true);
    }

    return savedPreferences ? null : (
        <CookieConsentStyled>
            <Close
                onClick={saveCookiePreferences}
                aria-label="opslaan"
                role="button"
            >
                <IconClose />
            </Close>
            <Heading>Wij maken gebruik van cookies</Heading>
            <Text>
                Voor de optimale beleving van Lees! Cadeau maken wij gebruik van
                cookies. Door gebruik te maken van onze website, ga je akkoord
                met ons{" "}
                <Link href="/cookie-privacy" passHref prefetch={false}>
                    <StyledLink>Cookies & Privacy statement</StyledLink>
                </Link>
                .
            </Text>
            <Option>
                <OptionButton
                    type="button"
                    isActive={preferencesCookies}
                    onClick={() => setPreferencesCookies(true)}
                    aria-label="accepteren"
                >
                    <IconCheck />
                </OptionButton>
                <OptionButton
                    type="button"
                    isActive={!preferencesCookies}
                    isNegative
                    onClick={() => setPreferencesCookies(false)}
                    aria-label="afslaan"
                >
                    <IconClose />
                </OptionButton>
                <OptionText>Voorkeuren</OptionText>
            </Option>
            <Option>
                <OptionButton
                    type="button"
                    isActive={statisticsCookies}
                    onClick={() => setStatisticsCookies(true)}
                    aria-label="accepteren"
                >
                    <IconCheck />
                </OptionButton>
                <OptionButton
                    type="button"
                    isActive={!statisticsCookies}
                    isNegative
                    onClick={() => setStatisticsCookies(false)}
                    aria-label="afslaan"
                >
                    <IconClose />
                </OptionButton>
                <OptionText>Statistieken</OptionText>
            </Option>
            <Option>
                <OptionButton
                    type="button"
                    isActive={marketingCookies}
                    onClick={() => setMarketingCookies(true)}
                    aria-label="accepteren"
                >
                    <IconCheck />
                </OptionButton>
                <OptionButton
                    type="button"
                    isActive={!marketingCookies}
                    isNegative
                    onClick={() => setMarketingCookies(false)}
                    aria-label="afslaan"
                >
                    <IconClose />
                </OptionButton>
                <OptionText>Marketing</OptionText>
            </Option>
            <Controls>
                <Link href="/cookie-privacy" passHref prefetch={false}>
                    <Button element="a" variant="ghost">
                        Meer info
                    </Button>
                </Link>
                <Button
                    variant="primary"
                    onClick={saveCookiePreferences}
                    aria-label="opslaan"
                >
                    Akkoord
                </Button>
            </Controls>
        </CookieConsentStyled>
    );
}
