const colors = {
    peach: "#FF7871",
    peachDark: "#F46A63",
    peachLighter: "#FFF2F1",
    peachLight: "#ff908b",
    lightNight: "#002b8b",
    darkNight: "#00174B",
    darkerNight: "#000F2B",
    darkestNight: "#01163D",
    white: "#FFF",
    green: "#40CB9F",
    greenDark: "#23B889",
    red: "#E24949",
    redDark: "#D13434",
    lightGrey: "#E7E7E7",
    athensGrey: "#FAFAFB",
    purple: "#B478FF"
};

export default colors;
