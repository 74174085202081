import { forwardRef } from "react";
import PropTypes from "prop-types";

import { ButtonStyled, ButtonLinkStyled } from "./Button.styled";

const Button = forwardRef((props, ref) => {
    const { children, variant, element } = props;

    return element === "a" ? (
        <ButtonLinkStyled variant={variant} ref={ref} {...props}>
            {children}
        </ButtonLinkStyled>
    ) : (
        <ButtonStyled variant={variant} ref={ref} {...props}>
            {children}
        </ButtonStyled>
    );
});

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    element: PropTypes.string,
    variant: PropTypes.oneOf([
        "primary",
        "secondary",
        "ghost",
        "outlined",
        "inline"
    ])
};

Button.defaultProps = {
    element: "button",
    variant: "primary",
    className: ""
};

export default Button;
