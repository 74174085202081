import { createGlobalStyle } from "styled-components";
import media from "constants/media";

const GlobalStyle = createGlobalStyle`
    :root {
        --font-DINPro: "DIN Pro", sans-serif;
        --font-DINProCondensed: "DIN Pro Condensed", sans-serif;
        --font-Agne: "Agne", sans-serif;

        --color-peach: #ff7871;
        --color-peachDark: #f46a63;
        --color-peachLighter: #fff2f1;
        --color-peachLight: #ff908b;

        --color-lightNight: #002b8b;
        --color-darkNight: #00174b;
        --color-darkerNight: #000f2b;
        --color-darkestNight: #01163d;

        --color-green: #40cb9f;
        --color-greenDark: #23b889;

        --color-red: #e24949;
        --color-redDark: #d13434;

        --color-white: #ffffff;
        --color-lightGrey: #e7e7e7;
        --color-athensGrey: #fafafb;

        --color-purple: #b478ff;
    }

    * {
        box-sizing: border-box;
    }

    html, body, h1, h2, h3, h4, h5, p, ul, ol {
        margin: 0;
        padding: 0;
    }

    html, body, #__next {
        height: 100%;
        min-height: 100%;
    }

    body, button, input, select, textarea {
        font-family: var(--font-DINPro);
    }

    body {
        color: var(--color-darkNight);

        &.ReactModal__Body--open {
            overflow: hidden;
        }
    }

    a, button {
        color: inherit;
        font-size: inherit;
        line-height: inherit;

        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    button {
        padding: 0;

        background: transparent;

        border: 0;
        outline: none;
    }

    .react-modal {
        position: absolute;

        z-index: 14;

        width: 100%;
        max-height: 100%;

        overflow: auto;

        background: transparent;
        outline: none;
        opacity: 0;

        transition: 0.4s opacity ease-in-out;

        svg g {
            fill: var(--color-peach);
        }


        ${media.mdUp`
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            padding: 40px 20px;
        `}

        &-overlay {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 13;

            background: rgba(0, 23, 75, 0.5);
        }

    }

    .ReactModal__Overlay {
        &--after-open .react-modal {
            opacity: 1;
        }

        &--before-close .react-modal {
            opacity: 0;
        }
    }
`;

export default GlobalStyle;
