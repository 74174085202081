import styled, { css } from "styled-components";
import media from "constants/media";

const primaryButtonStyles = css`
    position: relative;

    padding: 10px 20px;

    color: var(--color-white);
    font-weight: bold;
    font-size: 14px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;

    background: var(--color-peach);

    box-shadow: 2px 2px 0 0 var(--color-purple);

    transition: 0.2s background ease-in-out;

    ${media.mdUp`
        padding: 10px 30px 15px;

        font-size: 16px;
    `}

    &:hover,
    &:focus {
        background: var(--color-peachDark);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const secondaryButtonStyles = css`
    padding: 10px 20px;

    color: var(--color-white);
    font-weight: bold;
    font-size: 14px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;

    background: var(--color-darkNight);

    box-shadow: 2px 2px 0 0 rgba(180, 120, 255, 1);

    transition: 0.2s background ease-in-out;

    ${media.mdUp`
        padding: 10px 30px 15px;

        font-size: 16px;
    `}

    &:hover,
    &:focus {
        background: var(--color-darkerNight);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const ghostButtonStyled = css`
    padding-bottom: 5px;

    color: var(--color-peach);
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;

    border-bottom: 1px solid var(--color-peach);

    transition: 0.3s color ease-in-out;

    ${media.mdUp`
        padding-bottom: 3px;

        font-size: 16px;
    `}

    &:hover,
    &:focus {
        color: var(--color-peachLight);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const inlineButtonStyled = css`
    color: var(--color-peach);
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    text-decoration: underline;

    transition: 0.3s color ease-in-out;

    &:hover,
    &:focus {
        color: var(--color-peachLight);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const outlinedButtonStyles = css`
    position: relative;

    padding: 5px 20px 10px;

    color: var(--color-peach);
    font-weight: bold;
    font-size: 14px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;

    background: var(--color-white);
    border: 1px solid var(--color-peach);
    box-shadow: 2px 2px 0 0 var(--color-peach);

    transition: 0.2s background ease-in-out;

    ${media.mdUp`
        padding: 10px 30px 15px;

        font-size: 16px;
    `}

    &:hover,
    &:focus {
        background: var(--color-peachLighter);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.2;
    }
`;

const ButtonStyles = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    margin: 0;
    padding: 0;

    background: transparent;
    border: 0;
    outline: 0;

    cursor: pointer;

    user-select: none;

    /* stylelint-disable-next-line */
    ${({ withSpacedLetters }) =>
        withSpacedLetters &&
        css`
            letter-spacing: 2px;
        `}

    ${({ variant }) =>
        (variant === "primary" && primaryButtonStyles) ||
        (variant === "secondary" && secondaryButtonStyles) ||
        (variant === "ghost" && ghostButtonStyled) ||
        (variant === "inline" && inlineButtonStyled) ||
        (variant === "outlined" && outlinedButtonStyles)}
`;

export const ButtonStyled = styled.button`
    ${ButtonStyles}
`;

export const ButtonLinkStyled = styled.a`
    ${ButtonStyles}
`;
